<aside class="main-sidebar" [class.collapse]="isCollapsed" style="background-color: #ffffff">
    <div class="logo">
        <img
            [routerLink]="['/', homePageRoute]"
            [style.display]="isCollapsed ? 'block' : 'none'"
            src="assets/maersk_micro.svg"
        />
        <img
            [routerLink]="['/', homePageRoute]"
            [style.display]="!isCollapsed ? 'block' : 'none'"
            src="assets/maersk_mini.svg"
        />
    </div>

    <ul class="sidebar-menu">
        <li>
            <div class="group-icons" (click)="handleMenuToggle()">
                <img [style.display]="isCollapsed ? 'block' : 'none'" src="assets/menu/arrow-right.svg" />
                <img [style.display]="!isCollapsed ? 'block' : 'none'" src="assets/menu/double-arrow-left.svg" />
            </div>
        </li>
        <div *ngIf="isPrivate" class="ui-input-search__form" [style.display]="!isCollapsed ? 'block' : 'none'">
            <input
                type="text"
                [(ngModel)]="searchValue"
                [ngModelOptions]="{ standalone: true }"
                class="ui-input-search"
                placeholder="Search..."
            />
            <i class="huub-material-icon ui-input-clear__icon" icon="search" size="extra-extra-extra-small"></i>
        </div>
        <ng-template [ngIf]="isCollapsed" [ngIfElse]="expandedMenu">
            <div
                class="menu__mini__icon-container"
                [class.bottom-menu]="menuEntry.isBottomFixed"
                *ngFor="let menuEntry of menuConfig"
            >
                <label class="icons-menu-new" style="width: auto; display: flex">
                    <img
                        [class.active]="checkIfActive(menuEntry)"
                        [src]="'assets/menu/' + menuEntry.icon + '.svg'"
                        style="width: auto; display: flex"
                    />
                </label>
                <div class="menu__mini__options" [class.bottom-menu]="menuEntry.isBottomFixed">
                    <div class="menu__mini__options__title">{{ menuEntry.name }}</div>
                    <div class="menu__mini__options__item" *ngFor="let child of menuEntry.childrens">
                        <a *ngIf="child?.route" [routerLink]="[child.route]">{{ child?.name }}</a>
                        <a *ngIf="child?.externalRoute" [href]="child?.externalRoute" target="_blank">{{
                            child?.name
                        }}</a>
                        <a *ngIf="child?.action" (click)="handleClickActionMenu(child.action)">{{ child?.name }}</a>
                        <div *ngIf="child?.beta" class="badge-info ml-xxs badge-maersk">Beta</div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #expandedMenu>
            <div
                class="menu-container"
                [class.bottom-menu]="menuEntry.isBottomFixed"
                [class.active]="menuEntry.isActive"
                *ngFor="let menuEntry of menuUpdated; let i = index"
            >
                <div class="menu-title" (click)="handleOpenSubMenu(i, !menuEntry.isActive)">
                    <label class="icons-menu-new"
                        ><img
                            [class.active]="checkIfActive(menuEntry)"
                            [src]="'assets/menu/' + menuEntry.icon + '.svg'"
                            style="width: auto; display: flex"
                        />
                    </label>
                    <span class="menu-title__label">{{ menuEntry.name }}</span>
                    <div class="arrow-wrapper">
                        <i class="huub-material-icon down" [class.up]="menuEntry.isActive" icon="chevron-right"></i>
                        <i class="fa fa-angle-up pull-right up"></i>
                    </div>
                </div>
                <div class="menu-options">
                    <div class="menu-options__item" *ngFor="let child of menuEntry.childrens">
                        <a *ngIf="child?.route" [routerLink]="[child.route]">{{ child?.name }}</a>
                        <a *ngIf="child?.externalRoute" [href]="child?.externalRoute" target="_blank">{{
                            child?.name
                        }}</a>
                        <a *ngIf="child?.action" (click)="handleClickActionMenu(child.action)">{{ child?.name }}</a>
                        <div *ngIf="child?.beta" class="badge-info badge-maersk">Beta</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ul>
</aside>
