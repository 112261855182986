import { environment } from '../environments/environment';
import { ReceptionSandboxModule } from './init.module';

import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { start as singleSpaStart } from 'single-spa';
import { getSingleSpaExtraProviders } from 'single-spa-angular';




if (environment.production) {
    enableProdMode();
}

singleSpaStart();

const appId = 'container-app';


platformBrowserDynamic(getSingleSpaExtraProviders()).bootstrapModule(ReceptionSandboxModule)
  .then(module => {
    NgZone.isInAngularZone = () => {
        debugger;
      // @ts-ignore
      return window.Zone.current._properties[appId] === true;
    };

    const rootZone = module.injector.get(NgZone);

    // tslint:disable-next-line:no-string-literal
    rootZone['_inner']._properties[appId] = true;
  })
  .catch(err => console.error(err));

// platformBrowserDynamic()
//     .bootstrapModule(ReceptionSandboxModule)
//     .catch((err) => console.error(err));
