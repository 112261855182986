export const MENU_PRIVATE_CONFIG = [
    {
        name: 'Clients',
        icon: 'clients',
        isActive: false,
        childrens: [
            {
                name: 'Clients',
                route: 'client',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Shipping prices',
                route: 'shipping-prices',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Customers',
        icon: 'customers',
        isActive: false,
        childrens: [
            {
                name: 'Customers',
                route: 'customer',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Suppliers',
        icon: 'purchases',
        isActive: false,
        childrens: [
            {
                name: 'Suppliers',
                route: 'supplier',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Import suppliers',
                route: 'suppliers_bulk',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Products',
        icon: 'tags',
        isActive: false,
        childrens: [
            {
                name: 'Products',
                route: 'product_list',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Stock & locations',
                route: 'stock_view',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Import products',
                route: 'products_bulk',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Orders',
        icon: 'shopping-bag',
        isActive: false,
        childrens: [
            {
                name: 'Sales Orders management',
                route: 'sales-order',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'POs',
                route: 'purchaseorders',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Import POs',
                route: 'po_bulk',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'ASNs',
                route: 'asn-list',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Create ASN',
                route: 'create-asn',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Returns',
                route: 'returns',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Shipping',
        icon: 'truck',
        isActive: false,
        childrens: [
            {
                name: 'Outbound',
                route: 'outbound',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Delivery Experience',
                route: 'delivery/brand',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Carriers Contract',
                route: 'carrier-account',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Configurations',
        icon: 'configuration',
        isActive: false,
        childrens: [
            {
                name: 'Users list',
                route: 'user_list',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'User groups',
                route: 'user_groups',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Support data',
                route: 'support',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Stock Adjustment',
                route: 'stock_management',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Global operations',
        icon: 'global-operations',
        isActive: false,
        childrens: [
            {
                name: 'EXT 3 PL Integrations',
                route: 'ext_3pl',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Knowledge Center',
        icon: 'knowledge-center',
        isActive: false,
        childrens: [
            {
                name: 'Open Knowledge Center',
                externalRoute: 'https://help.ecl-platform.maersk.com/',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'User',
        icon: 'user',
        isActive: false,
        isBottomFixed: true,
        childrens: [
            {
                name: 'Profile',
                route: 'profile',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Logout',
                action: 'logout',
            },
        ],
    },
];
