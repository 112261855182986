import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { findIndex } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { TransportContainer } from '../transport/transport.container';
import jwt_decode from 'jwt-decode';

const USER_ROLES = {
    ADMIN: 'Admin',
    WAREHOUSE: 'Warehouse',
    MANAGER: 'Manager',
    CLIENT: 'Client',
};

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private _token;
    private jwtService = new JwtHelperService();
    // Have a JSON inside
    private userData = null;
    // Return a object from JSON
    get userDataParsed() {
        return JSON.parse(this.userData);
    }
    public get token() {
        return this._token;
    }
    private set token(value: string) {
        this._token = value;
    }
    constructor(
        private transport: TransportContainer,
        private cookieService: CookieService,
        @Inject('ENVIRONMENT') ENVIRONMENT
    ) {
        this.token = localStorage.getItem('accessToken');
        if (this.token) {
            this.setUserData();
            if (!localStorage.getItem('3pl_token')) {
                this.transport
                    .authenticate3Pl({ email: 'webmaster@thehuub.co', password: 'webmaster' })
                    .subscribe((response) => {
                        localStorage.setItem('3pl_token', response.token);
                    });
            }
        }
    }
    private setUserData() {
        let cachedUserData = localStorage.getItem('userData');

        if (!!cachedUserData) {
            this.userData = atob(this.token.split('.')[1] || '') || '{}';
            localStorage.setItem('userData', this.userData);
            return;
        }
        if (this.userData == cachedUserData) {
            return;
        }
        if (cachedUserData && cachedUserData != this.userData) {
            this.userData = cachedUserData;
            localStorage.setItem('userData', this.userData);
        }
    }

    isClient() {
        return (
            findIndex(this.userDataParsed.roles, {
                role: USER_ROLES.CLIENT,
            }) > -1
        );
    }

    isAdmin() {
        return (
            findIndex(this.userDataParsed.roles, {
                role: USER_ROLES.ADMIN,
            }) > -1
        );
    }

    logoutClient() {
        this.token = '';
        this.userData = {};
        localStorage.removeItem('userData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('3pl_token');
        sessionStorage.removeItem('huubapp_iframes_count');
        const hasCookie = this.cookieService.get('session_id_huubapp');
        if (hasCookie) {
            this.transport.logoutOnHuubapp().subscribe((response: any) => {
                this.cookieService.deleteAll('/');
                this.cookieService.deleteAll('/', '.thehuub.io');
            });
        }
    }

    private getDecodedToken(): any {
        if (!this.token) {
            return null;
        }

        try {
            return jwt_decode(this.token);
        } catch (Error) {
            return null;
        }
    }

    private isDateAfterToday(date: Date) {
        return date.valueOf() > new Date().valueOf();
    }

    private isTokenExpired() {
        if (!this.token) return true;
        const decodedToken = this.getDecodedToken();
        if (!decodedToken) return true;

        const expirationEpoch = decodedToken.exp;
        // token exp is given in seconds so we multiply it by 1000
        // to convert it to milliseconds
        const expirationDate = new Date(expirationEpoch * 1000);

        if (!this.isDateAfterToday(expirationDate)) {
            this.logoutClient();
            return true;
        }
        return false;
    }

    public isNotLogedIn() {
        return this.isTokenExpired();
    }

    setCookie = (name, value, days = 7, path = '/') => {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie =
            name +
            '=' +
            encodeURIComponent(value) +
            '; expires=' +
            expires +
            '; path=' +
            path +
            ';SameSite=None; Secure';
    };

    getCookie = (name) => {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    };

    deleteCookie = (name, path) => {
        this.setCookie(name, '', -1, path);
    };
}
