import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { UserService } from '@maersk-global/huub-utils';

@Injectable()
export class TransportInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { userToken } = UserService;

        return of(userToken).pipe(
            switchMap((token) => {
                const clone = req.clone({
                    setHeaders: {
                        jwt: token,
                    },
                });

                return next.handle(clone);
            })
        );
    }
}
