import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { TransportContainer } from '../transport/transport.container';
import { CookieService } from 'ngx-cookie-service';
import { ViewManagementService } from '../services/view-management.service';
import { UserService } from '@maersk-global/huub-utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private authenticationService!: AuthenticationService;
    private viewManagementService!: ViewManagementService;
    private environment;
    private eclPlatformUrl = '';
    private isStaging = false;
    constructor(
        private router: Router,
        private transport: TransportContainer,
        private cookieService: CookieService,
        private userService: UserService,
        @Inject('ENVIRONMENT') ENVIRONMENT
    ) {
        this.environment = ENVIRONMENT;
        this.isStaging = this.environment['IS_STAGING'];
        this.eclPlatformUrl = this.environment['ECL_PLATFORM'];
        this.viewManagementService = new ViewManagementService();
    }

    //TO-DO: delete this code
    hasToggle(toggleConfig: any): boolean {
        const userId = this.userService.getUserId();
        const huubClientId = Number(this.userService.getHuubClientId());
        return (
            toggleConfig.data.user_ids.includes(userId) ||
            toggleConfig.data.brand_ids.includes(huubClientId) ||
            toggleConfig.data.user_ids.includes('all') ||
            toggleConfig.data.brand_ids.includes('all')
        );
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.authenticationService = new AuthenticationService(this.transport, this.cookieService, this.environment);
        let toggleData: any = {};
        if (
            this.viewManagementService.hideClientView == undefined &&
            !this.authenticationService.isNotLogedIn() &&
            !this.isStaging
        ) {
            UserService.setUserData(localStorage.getItem('accessToken'), JSON.parse(localStorage.getItem('userData')));
            toggleData = await this.transport.getToggleData('hide_client_view');
            this.viewManagementService.hideClientView = this.hasToggle(toggleData);
        }

        // We check if the hide client view toggle is true and if the environment is not staging.
        // If so, we redirect to the ECL platform.
        if (this.viewManagementService.hideClientView == true && !this.isStaging) {
            window.location.href = this.eclPlatformUrl;
            return;
        }

        if (this.authenticationService.isNotLogedIn()) {
            if (!state.url.includes('admin_auth')) {
                return this.router.parseUrl('/admin_auth/login');
            } else {
                return true;
            }
        }

        if (this.authenticationService.isAdmin()) {
            if (state.url.includes('/private')) {
                return true;
            } else {
                return this.router.parseUrl('/private');
            }
        } else if (this.authenticationService.isClient()) {
            if (state.url.includes('/client')) {
                return true;
            } else {
                return this.router.parseUrl('/client');
            }
        }
    }
}
