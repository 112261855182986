import { TransportContainer } from './../../common/scripts/transport/transport.container';
import { AuthenticationService } from './../../common/scripts/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ConsulService, UserService } from '@maersk-global/huub-utils';
@Component({
    selector: 'app-iframe-wrapper',
    templateUrl: './iframe-wrapper.component.html',
    styleUrls: ['./iframe-wrapper.component.scss'],
})
export class IframeWrapperComponent implements OnInit {
    src: SafeResourceUrl;
    powerBIUrl = 'https://app.powerbi.com/reportEmbed?reportId=';
    powerBiConfigParameters =
        '&autoAuth=true&ctid=d553eaa6-3e95-4086-9921-2991cb03f024&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D';

    dashboards = {
        performance: '68dc3916-d82a-4737-b56b-92631a8c9992', //performance
        business: '63ff36d7-296d-4442-a135-8b1773e19d21', //business
        'stock-insights': '412bf3a3-38d9-4f04-928a-5b81407bb9da', //stock-insights
        'carbon-footprint': '301df74c-0644-44c1-b48d-b3e0653aadc2', //carbon-footprint
        wh_stock_adjustments: '845418ba-eaf9-4dfe-a4d9-4ad525fb7637', //warehouse stock adjustments
    };

    isDashboard = false;
    is3PL = false;
    isReturns = false;
    isProductsImport = false;
    isCreateAsn = false;
    isAsnList = false;
    isProductsList = false;

    constructor(
        private route: ActivatedRoute,
        private transportContainer: TransportContainer,
        private router: Router,
        private sanitizer: DomSanitizer,
        private authenticationService: AuthenticationService,
        private cookieService: CookieService,
        private consulService: ConsulService,
        private userService: UserService,
        private transport: TransportContainer
    ) {}

     ngOnInit(): void {
        this.clearPreviousState();
        this.generateIframeSourceBasedOnRoute();
    }

    clearPreviousState() {
        sessionStorage.removeItem('state');
    }

    generateIframeSourceBasedOnRoute() {
        const routerUrl = this.router.url.split('/')[2];
        this.is3PL = routerUrl.includes('ext_3pl');
        this.isReturns = this.router.url === '/client/returns';
        this.isCreateAsn = this.router.url.includes('create-asn');
        this.isAsnList = this.router.url.includes('asn-list');
        this.isProductsImport = this.router.url === '/client/products-import';
        this.isProductsList = this.router.url.includes('/client/products_list');
        this.isDashboard = this.dashboards[routerUrl];
        if (this.isDashboard) {
            this.setDashboardsIframeSource(routerUrl);
        } else if (this.is3PL) {
            this.set3PLIframeSource();
        } else if (this.isReturns) {
            this.setGenericIFrameSource(environment.EFULFILMENT_SPA_CONTAINER_CONFIG.RETURNS_APP);
        } else if (this.isCreateAsn) {
            this.setGenericIFrameSource(environment.EFULFILMENT_SPA_CONTAINER_CONFIG.CREATE_ASN_APP);
        } else if (this.isAsnList) {
            this.setGenericIFrameSource(environment.EFULFILMENT_SPA_CONTAINER_CONFIG.ASN_LIST_APP);
        } else if (this.isProductsImport) {
            this.setGenericIFrameSource(environment.EFULFILMENT_SPA_CONTAINER_CONFIG.PRODUCT_APP);
        } else if (this.isProductsList) {
            this.setProductListSource();
        } else {
            this.setHuubappIframeSource();
        }
    }

    setProductListSource() {
        let src = environment.EFULFILMENT_SPA_CONTAINER_CONFIG.PRODUCT_APP + '/products_list';
        src += '?token=' + localStorage.getItem('accessToken');
        const search = this.route.snapshot.queryParamMap.get('search');
        if (search) src += '&search=' + search;
        this.updateIframeSource(src);
    }

    setDashboardsIframeSource(routerUrl: string) {
        const source = this.powerBIUrl + this.dashboards[routerUrl] + this.powerBiConfigParameters;
        this.updateIframeSource(source);
    }

    set3PLIframeSource() {
        this.transport
            .authenticate3Pl({ email: 'webmaster@thehuub.co', password: 'webmaster' })
            .subscribe((response) => {
                localStorage.setItem('3pl_token', response.token);
                const source =
                    environment.EFULFILMENT_SPA_CONTAINER_CONFIG.URL_3PL +
                    '?token=' +
                    localStorage.getItem('3pl_token');
                this.updateIframeSource(source);
            });
    }

    setGenericIFrameSource(endpoint: string) {
        const source = endpoint + '?token=' + localStorage.getItem('accessToken');
        this.updateIframeSource(source);
    }

    setHuubappIframeSource() {
        let src = this.route.snapshot.data['src'];
        if (src.includes('so/drop/so_id')) {
            const id = this.route.snapshot.params['id'];
            src += '/' + id;
        }

        if (src.includes('product_list')) {
            const ean = this.route.snapshot.params['ean'];
            if (ean) src += '?search_product=' + ean;
        }

        let iframeUrl = environment.EFULFILMENT_SPA_CONTAINER_CONFIG.URL_HUUB_APP + src;

        const cookie = this.cookieService.get('session_id_huubapp');
        let storedCount = sessionStorage.getItem('huubapp_iframes_count');
        let iframesCount = storedCount == null ? 0 : Number(storedCount);
        if (iframesCount > 0 && cookie != null) {
            this.updateHuubappIframesCount(iframesCount + 1);
            this.updateIframeSource(iframeUrl);
            return;
        }

        //if it's the first time user connects to huubapp on this session, we forget the last session and send a new token
        this.cookieService.delete('session_id_huubapp');
        this.transportContainer.logoutOnHuubapp().subscribe((response) => {
            let iframeUrl =
                environment.EFULFILMENT_SPA_CONTAINER_CONFIG.URL_HUUB_APP +
                src +
                (src.includes('?') ? '&' : '?') +
                'token=' +
                localStorage.getItem('accessToken');
            this.updateHuubappIframesCount(iframesCount + 1);
            this.updateIframeSource(iframeUrl);
        });
    }

    updateHuubappIframesCount(count: number) {
        sessionStorage.setItem('huubapp_iframes_count', count.toString());
    }

    updateIframeSource(source: string) {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(source);
    }

    getSessionHuubApp() {
        const hasCookie = this.cookieService.get('session_id_huubapp');
        if (!this.isDashboard && !this.is3PL && !this.isProductsImport && !this.isReturns && !hasCookie) {
            this.transportContainer.getSession().subscribe((data) => {
                const session = data.session;
                this.cookieService.deleteAll('/');
                const date = new Date();
                // Set it expire in 7 days
                date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
                this.cookieService.set('session_id_huubapp', session, date, '/', null, true, 'None');
            });
        }
    }

    handleToggleValue(toggleConfig: any): boolean {
        const userId = this.userService.getUserId();
        const huubClientId = Number(this.userService.getHuubClientId());
        return (
            toggleConfig.data.user_ids.includes(userId) ||
            toggleConfig.data.brand_ids.includes(huubClientId) ||
            toggleConfig.data.user_ids.includes('all') ||
            toggleConfig.data.brand_ids.includes('all')
        );
    }

    ngOnDestroy(): void {}
}
