import { WrapperContentComponent } from './../components/wrapper-content/wrapper-content.component';
import { AuthGuard } from './../common/scripts/guards/auth.guard';
import { Routes } from '@angular/router';
import { EmptyLayoutComponent } from '../pages/empty-layout/empty-layout.component';
import { HomeComponent } from '../pages/home/home.component';

/* Pages */

/* Helpers */

/* URL Configuration */
import { URL_CONFIG } from './url.config';
import { IframeWrapperComponent } from '../pages/iframe-wrapper/iframe-wrapper.component';

const APP_ROUTES = [
    {
        path: '',
        component: WrapperContentComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'sales-order',
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'salesOrder' },
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'imports',
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'imports' },
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'ims',
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'ims' },
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'delivery',
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'delivery' },
            },
        ],
        canActivate: [AuthGuard],
    },

    {
        path: 'settings',
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'settings' },
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'dashboards',
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'releases' },
            },
        ],
        canActivate: [AuthGuard],
    },
];
export const ROUTES: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'client',
                component: HomeComponent,
                canActivate: [AuthGuard],
                children: [
                    ...APP_ROUTES,
                    /**{
                        path: 'shipping-prices',
                        children: [
                            {
                                path: '**',
                                loadChildren: () =>
                                    import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                                data: { app: 'shippingPricesBrand' },
                            },
                        ],
                        canActivate: [AuthGuard],
                    },*/
                    {
                        path: 'products_list',
                        component: IframeWrapperComponent,
                        data: { src: 'client/product_list' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'products_list/:ean',
                        component: IframeWrapperComponent,
                        data: { src: 'client/product_list' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'purchaseorders',
                        component: IframeWrapperComponent,
                        data: { src: 'client/po' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'supplier',
                        component: IframeWrapperComponent,
                        data: { src: 'client/supplier' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'customer',
                        component: IframeWrapperComponent,
                        data: { src: 'client/customer' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'performance',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'business',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'stock-insights',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'carbon-footprint',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'profile',
                        component: IframeWrapperComponent,
                        data: { src: 'private/user/profile' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'delivery-details/:id',
                        component: IframeWrapperComponent,
                        data: { src: 'client/so/drop/so_id' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'returns',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'create-asn',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'products-import',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'wh_stock_adjustments',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                ],
            },
            {
                path: 'private',
                component: HomeComponent,
                canActivate: [AuthGuard],
                children: [
                    ...APP_ROUTES,
                    {
                        path: 'shipping-prices',
                        children: [
                            {
                                path: '**',
                                loadChildren: () =>
                                    import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                                data: { app: 'shippingPricesAdmin' },
                            },
                        ],
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'carrier-account',
                        children: [
                            {
                                path: '**',
                                loadChildren: () =>
                                    import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                                data: { app: 'carrierAcount' },
                            },
                        ],
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'client',
                        component: IframeWrapperComponent,
                        data: { src: 'private/client' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'customer',
                        component: IframeWrapperComponent,
                        data: { src: 'private/customer' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'supplier',
                        component: IframeWrapperComponent,
                        data: { src: 'private/supplier' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'suppliers_bulk',
                        component: IframeWrapperComponent,
                        data: { src: 'imports/suppliers_bulk' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'so_bulk',
                        component: IframeWrapperComponent,
                        data: { src: 'imports/so_bulk' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'product_list',
                        component: IframeWrapperComponent,
                        data: { src: 'private/product_list' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'stock_view',
                        component: IframeWrapperComponent,
                        data: { src: 'private/stock_view' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'products_bulk',
                        component: IframeWrapperComponent,
                        data: { src: 'imports/products_bulk' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'purchaseorders',
                        component: IframeWrapperComponent,
                        data: { src: 'private/po' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'po_bulk',
                        component: IframeWrapperComponent,
                        data: { src: 'imports/po_bulk' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'receptions_bulk',
                        component: IframeWrapperComponent,
                        data: { src: 'imports/receptions_bulk' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'returns',
                        component: IframeWrapperComponent,
                        data: { src: 'private/returns' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'outbound',
                        component: IframeWrapperComponent,
                        data: { src: 'outbound' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'user_list',
                        component: IframeWrapperComponent,
                        data: { src: 'private/user_list' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'user_groups',
                        component: IframeWrapperComponent,
                        data: { src: 'private/user_group' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'support',
                        component: IframeWrapperComponent,
                        data: { src: 'support' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'stock_management',
                        component: IframeWrapperComponent,
                        data: { src: 'private/stock_management' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'ext_3pl',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'profile',
                        component: IframeWrapperComponent,
                        data: { src: 'private/user/profile' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'delivery-details/:id',
                        component: IframeWrapperComponent,
                        data: { src: 'private/so/drop/so_id' },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'asn-list',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'create-asn',
                        component: IframeWrapperComponent,
                        data: {},
                        canActivate: [AuthGuard],
                    },
                ],
            },
        ],
    },
    {
        path: 'authentication',
        component: EmptyLayoutComponent,
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'authentication' },
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'admin_auth',
        component: EmptyLayoutComponent,
        children: [
            {
                path: '**',
                loadChildren: () => import('../components/spa-host/spa-host.module').then((m) => m.SpaHostModule),
                data: { app: 'admin_auth' },
            },
        ],
        canActivate: [AuthGuard]
    },

    // {
    //     path: "",
    //     component: "",
    //     resolve: {
    //         notificationsSettingsDetails: ""
    //     },
    //     runGuardsAndResolvers: 'always'
    // }
];
