import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-wrapper-content',
    templateUrl: './wrapper-content.component.html',
    styleUrls: ['./wrapper-content.component.scss'],
})
export class WrapperContentComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
