import MenuConfig from '../types/menu-config';

export const MENU_CLIENT_CONFIG: MenuConfig[] = [
    {
        name: 'Products',
        icon: 'tags',
        isActive: false,
        childrens: [
            {
                name: 'Products List',
                route: 'products_list',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Import Products',
                route: 'products-import',
            },
        ],
    },
    {
        name: 'Stock',
        icon: 'stock',
        isActive: false,
        childrens: [
            {
                name: 'Stock Management',
                route: 'ims',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Stock insights',
                route: 'stock-insights',
                beta: true,
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'WH Stock adjustments',
                route: 'wh_stock_adjustments',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Purchases',
        icon: 'purchases',
        isActive: false,
        childrens: [
            {
                name: 'Purchases',
                route: 'purchaseorders',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Suppliers',
                route: 'supplier',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Import purchases',
                route: 'imports/purchaseorders',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Sales Orders Center',
        icon: 'shopping-bag',
        isActive: false,
        childrens: [
            {
                name: 'Sales Orders management',
                route: 'sales-order',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Pending deliveries',
                route: 'delivery/brand',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Customers management',
                route: 'customer',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Import customers',
                route: 'imports/customers',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Dashboards',
        icon: 'dashboards',
        isActive: false,
        childrens: [
            {
                name: 'Performance',
                route: 'performance',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Business',
                route: 'business',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Carbon footprint',
                route: 'carbon-footprint',
                beta: true,
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    /**{
        name: 'Billing',
        icon: 'billing-new',
        isActive: false,
        childrens: [
            {
                name: 'Shipping prices',
                route: 'shipping-prices',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },*/
    {
        name: 'Configurations',
        icon: 'configuration',
        isActive: false,
        childrens: [
            {
                name: 'Customer notifications',
                route: 'settings/notifications',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'Knowledge Center',
        icon: 'knowledge-center',
        isActive: false,
        childrens: [
            {
                name: 'Open Knowledge Center',
                externalRoute: 'https://help.ecl-platform.maersk.com/',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
        ],
    },
    {
        name: 'User',
        icon: 'user',
        isActive: false,
        isBottomFixed: true,
        childrens: [
            {
                name: 'Profile',
                route: 'profile',
                // canShow : (user): boolean =>{
                //     return true
                // }
            },
            {
                name: 'Logout',
                action: 'logout',
            },
        ],
    },
];
