import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ViewManagementService {
    private hideClient: boolean;

    public get hideClientView() {
        return this.hideClient;
    }

    public set hideClientView(value) {
        this.hideClient = value;
    }
}
