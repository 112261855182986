import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { MicroFrontendRouteReuseStrategy } from './config/route-reuse-strategy';
import { ROUTES } from './config/routes.config';
import { HomeComponent } from './pages/home/home.component';
import { ContainerMenuComponent } from './components/container-menu/container-menu.component';
import { EmptyLayoutComponent } from './pages/empty-layout/empty-layout.component';
import { WrapperContentComponent } from './components/wrapper-content/wrapper-content.component';
import { IframeWrapperComponent } from './pages/iframe-wrapper/iframe-wrapper.component';
import { TransportContainer } from './common/scripts/transport/transport.container';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';
import { ConsulService, UserService } from '@maersk-global/huub-utils';
/* Interceptors */
import { TransportInterceptor } from './common/scripts/transport/transport.container.interceptor';
@NgModule({
    declarations: [
        HomeComponent,
        ContainerMenuComponent,
        EmptyLayoutComponent,
        WrapperContentComponent,
        IframeWrapperComponent,
    ],
    imports: [BrowserModule, FormsModule, RouterModule.forChild(ROUTES), HttpClientModule],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: MicroFrontendRouteReuseStrategy,
        },
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TransportInterceptor,
            multi: true,
        },
    ],
    bootstrap: [],
})
export class AppModule {
    static setEnvironment(ENVIRONMENT): ModuleWithProviders<any> {
        return {
            ngModule: AppModule,
            providers: [
                {
                    provide: 'ENVIRONMENT',
                    useValue: ENVIRONMENT,
                },
                HttpClientModule,
                TransportContainer,
                UserService,
                ConsulService,
                // {
                //   provide: 'googleTagManagerId',
                //   useValue: GTM
                // }
            ],
        };
    }
    // , private gtmService: GoogleTagManagerService
    constructor(@Inject('ENVIRONMENT') ENVIRONMENT) {
        if (!ENVIRONMENT) {
            throw new Error(`
                For this module to be able to work you need to configure the ENVIRONMENT.
                Did you try to inject this module like this?

                imports: [
                  SalesordersModule.setEnvironment(environment)
                ]
            `);
        }

        // Injects GTM

        // UrlBuilderModule.setUrlConfig(URL_CONFIG);
    }
}
