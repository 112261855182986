<main>
    <app-container-menu
        (onClickMenuEvent)="handleMenuActions($event)"
        (onCollapseStatusChange)="isMenuCollapsed = $event"
    ></app-container-menu>
    <div class="content-wrapper" [class.collapsed]="isMenuCollapsed">
        <section class="child-app">
            <div style="height: 100%; width: 100%; display: flex; justify-content: center">
                <div
                    style="display: flex; justify-content: center; width: 100%"
                    [ngStyle]="{ maxWidth: isFlutterEnvironment ? '' : '1200px' }"
                >
                    <router-outlet></router-outlet>
                </div>
            </div>
        </section>
    </div>
</main>
