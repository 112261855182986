import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

/* Transport Config */
import { TRANSPORT_CONFIG } from './transport.container.config';
import { API } from '../constants/global.constant';
import { ConsulService } from '@maersk-global/huub-utils';

@Injectable()
export class TransportContainer {
    private HUUBAPP_ENDPOINT: String;
    endpoint3pl;

    constructor(
        private httpClient: HttpClient,
        @Inject('ENVIRONMENT') ENVIRONMENT,
        private consulService: ConsulService
    ) {
        this.HUUBAPP_ENDPOINT = ENVIRONMENT.URL_HUUB_APP;
        this.endpoint3pl = ENVIRONMENT.ENDPOINT_3PL;
    }
    public authenticate3Pl(body: { email: string; password: string }): Observable<any> {
        const url = `${this.endpoint3pl}${TRANSPORT_CONFIG.AUTHENTICATE_3PL}`;
        return this.httpClient.post(url, body);
    }

    public getSession(): Observable<any> {
        const url = `${this.HUUBAPP_ENDPOINT}${API.GET_SESSION_HUUBAPP}`;
        return this.httpClient.get(url);
    }

    isAuthenticatedOnHuubApp(): Observable<any> {
        const url = `${this.HUUBAPP_ENDPOINT}${API.IS_AUTHENTICATED_HUUBAPP}`;
        return this.httpClient.get(url);
    }

    public logoutOnHuubapp(): Observable<any> {
        const url = `${this.HUUBAPP_ENDPOINT}${API.LOGOUT_HUUBAPP}`;
        return this.httpClient.get(url, {
            headers: {},
            withCredentials: true,
        });
    }

    public getToggleData(toggleName: string): Promise<Object> {
        return this.consulService.getValue(`delivery_service_api/delivery/toggle?feature_name=${toggleName}`);
    }
}
