import { NgModule, ApplicationRef } from '@angular/core';
import { environment } from '../environments/environment';
import { InitComponent } from './init.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppModule } from '../src/app.module';
import { UserService } from '@maersk-global/huub-utils';

const appRoutes: Routes = [];

@NgModule({
    declarations: [InitComponent],
    imports: [
        RouterModule.forRoot(appRoutes),
        BrowserModule,
        FormsModule,
        AppModule.setEnvironment(environment.EFULFILMENT_SPA_CONTAINER_CONFIG),
        // AppModule.setEnvironment(environment.BLANK_PROJECT_CONFIG)
    ],
    entryComponents: [InitComponent],
})
export class ReceptionSandboxModule {
    ngDoBootstrap(app: ApplicationRef) {
        // const cachedToken = sessionStorage.getItem('accessToken');
        // const cachedUserData = sessionStorage.getItem('userData');

        // if (cachedToken && cachedUserData) {
        //     UserService.setUserData(
        //         cachedToken,
        //         JSON.parse(cachedUserData)
        //     );
        // } else {
        //     const urlParams = new URLSearchParams(window.location.search);
        //     const token = urlParams.get('token') || '';
        //     const userData = atob(token.split('.')[1] || '') || '{}';

        //     sessionStorage.setItem('accessToken', token);
        //     sessionStorage.setItem('userData', userData);

        //     UserService.setUserData(
        //         token,
        //         JSON.parse(userData)
        //     );
        // }

        // if (environment.domain !== '') {
        //     document.domain = environment.domain;
        // }

        UserService.setUserData('', {});

        app.bootstrap(InitComponent);
    }
}
