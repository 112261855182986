import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsulService, UserService } from '@maersk-global/huub-utils';
import { TransportContainer } from '../../common/scripts/transport/transport.container';
import { MENU_CLIENT_CONFIG } from '../../common/scripts/constants/menu.client.constant';
import { MENU_PRIVATE_CONFIG } from '../../common/scripts/constants/menu.private.constant';

@Component({
    selector: 'app-container-menu',
    templateUrl: './container-menu.component.html',
    styleUrls: ['./container-menu.component.scss'],
})
export class ContainerMenuComponent implements OnInit {
    @Output() onCollapseStatusChange = new EventEmitter();
    @Output() onClickMenuEvent = new EventEmitter();
    menuConfig = [...MENU_CLIENT_CONFIG];
    isCollapsed: boolean = true;
    searchValue;
    isPrivate: boolean;
    homePageRoute = 'client';
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private transport: TransportContainer,
        private userService: UserService
    ) {
        if (activatedRoute.snapshot.routeConfig.path == 'private') {
            this.homePageRoute = 'private';
            this.menuConfig = MENU_PRIVATE_CONFIG;
            this.isPrivate = true;
        }
    }

    ngOnInit(): void {
        UserService.setUserData(localStorage.getItem('accessToken'), JSON.parse(localStorage.getItem('userData')));
        // This way if we change the initial status of menu we don't have dependecies on homeComponent
        this.onCollapseStatusChange.emit(this.isCollapsed);
        this.checkTogglesMenu();
    }

    handleMenuToggle(): void {
        this.isCollapsed = !this.isCollapsed;
        this.onCollapseStatusChange.emit(this.isCollapsed);
    }

    handleOpenSubMenu(index: number, value: boolean) {
        if (!value) {
            this.menuUpdated[index].isActive = value;
            return;
        }
        let filtredArray = this.menuConfig.filter((menuEntry) => menuEntry.isActive);
        if (filtredArray.length) {
            filtredArray.forEach((entry) => (entry.isActive = false));
        }
        this.menuUpdated[index].isActive = value;
    }

    handleClickActionMenu(eventName) {
        this.onClickMenuEvent.emit(eventName);
    }

    checkIfActive(menuEntry) {
        const href = this.router.url;
        if (menuEntry.childrens.filter((entry) => href.includes(entry.route)).length > 0) return true;
        return false;
    }

    get menuUpdated() {
        if (!this.searchValue) return this.menuConfig;

        const valueUpperCase = this.searchValue.toLocaleUpperCase();
        return this.menuConfig.filter(
            (menuEntry) =>
                menuEntry.name.toLocaleUpperCase().includes(valueUpperCase) ||
                (menuEntry.childrens as any).filter((children) =>
                    children.name.toLocaleUpperCase().includes(valueUpperCase)
                ).length > 0
        );
    }

    checkTogglesMenu() {
        this.setReturnsMenu().then((res) => {});
        this.createASNMenu().then((res) => {});
        //this.setProductsImportMenu().then((res) => {});
        //this.setStockInsightsMenu().then((res) => {});
    }

    async setReturnsMenu(): Promise<void> {
        if (this.isPrivate) {
            return;
        }
        const returnsMenuToggle: any = await this.transport.getToggleData('returns-menu');
        if (this.handleToggleValue(returnsMenuToggle)) {
            this.menuConfig.splice(4, 0, {
                name: 'Returns',
                icon: 'returns',
                isActive: false,
                childrens: [
                    {
                        name: 'Returns management',
                        route: 'returns',
                    },
                ],
            });
        }
    }

    async setProductsImportMenu(): Promise<void> {
        if (this.isPrivate) {
            return;
        }
        const produtctsMenuToggle: any = await this.transport.getToggleData('import_products_menu');
        if (this.handleToggleValue(produtctsMenuToggle)) {
            const productsMenu = this.menuConfig.find((item) => item.name === 'Products');
            if (!productsMenu) {
                return;
            }
            const importProductsMenu = productsMenu.childrens.find((item) => item.name === 'Import Products');

            if (importProductsMenu) {
                return;
            }

            productsMenu.childrens.splice(1, 0, {
                name: 'Import Products',
                route: 'products-import',
            });
        }
    }

    async createASNMenu(): Promise<void> {
        if (this.isPrivate) {
            return;
        }
        const createASNMenuToggle: any = await this.transport.getToggleData('create-asn-menu');

        if (this.handleToggleValue(createASNMenuToggle)) {
            const ASNMenu = this.menuConfig.find((item) => item.name === 'Purchases');

            if (!ASNMenu) {
                return;
            }
            const createASNMenu = ASNMenu.childrens.find((item) => item.name === 'Create ASN');

            if (createASNMenu) {
                return;
            }

            ASNMenu.childrens.splice(3, 0, {
                name: 'Create ASN',
                route: 'create-asn',
            });
        }
    }

    async setStockInsightsMenu(): Promise<void> {
        if (this.isPrivate) {
            return;
        }
        // UserService.setUserData(localStorage.getItem('accessToken'), JSON.parse(localStorage.getItem('userData')));
        const stockInsightsMenuToggle: any = await this.transport.getToggleData('stock_insights_menu');
        if (!this.handleToggleValue(stockInsightsMenuToggle)) {
            const index = this.menuConfig.findIndex((x) => x.name === 'Stock');
            this.menuConfig[index].childrens.splice(1);
        }
    }

    handleToggleValue(menuToggleConfig: any): boolean {
        const userId = this.userService.getUserId();
        const huubClientId = Number(this.userService.getHuubClientId());
        return (
            menuToggleConfig.data.user_ids.includes(userId) ||
            menuToggleConfig.data.brand_ids.includes(huubClientId) ||
            menuToggleConfig.data.user_ids.includes('all') ||
            menuToggleConfig.data.brand_ids.includes('all')
        );
    }
}
