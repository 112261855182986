import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './../../common/scripts/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { filter, Subscription } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    isMenuCollapsed: boolean;
    isFlutterEnvironment = false;
    navigationEventsSubscription: Subscription;

    constructor(private authenticationService: AuthenticationService, private router: Router) {}

    ngOnInit(): void {
        this.checkFlutterEnvironment();
        this.handleRouteNavigation();
    }

    handleRouteNavigation() {
        this.navigationEventsSubscription = this.router.events
            .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
            .subscribe(() => {
                this.clearPreviousState();
                this.checkFlutterEnvironment();
            });
    }

    clearPreviousState() {
        sessionStorage.removeItem('state');
    }

    checkFlutterEnvironment() {
        this.isFlutterEnvironment = this.router.url === '/client/returns' || this.router.url === '/client/create-asn';
    }

    handleMenuActions(event: string) {
        switch (event) {
            case 'logout': {
                this.authenticationService.logoutClient();
                const shouldRedirectLogin = true;
                if (shouldRedirectLogin) {
                    this.router.navigate(['/admin_auth']);
                } else {
                    this.router.navigate(['/authentication']);
                }
                break;
            }

            default:
                console.error("Event on menu has been throw and isn't recognized EventName:" + event);
                break;
        }
    }
}
