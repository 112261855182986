<div class="iframe-container">
    <ng-container *ngIf="src">
        <iframe
            (load)="getSessionHuubApp()"
            class="iframe-wrapper"
            [src]="src"
            sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-downloads allow-popups allow-modals"
            allow="clipboard-read; clipboard-write"
        ></iframe
    ></ng-container>
</div>
